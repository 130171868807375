import datepicker from 'js-datepicker';
import 'js-datepicker/src/datepicker';



const modal = document.querySelector('.online-modal');
const modalClose = document.querySelectorAll('.js-close-online-modal');
const body = document.querySelector('body');
const initBtns = document.querySelectorAll('.online-consultation-btn-js');
const modalContainer = document.querySelector('.online-modal__container'); // Контейнер модалки




if(initBtns && initBtns.length > 0) {

  initBtns.forEach(btn => {
    btn.addEventListener('click', () => {
        modal.classList.add('activeModal'); // Додаємо клас для відкриття модалки
        body.classList.add('noScroll'); // Можна додати клас для блокування прокручування сторінки, якщо потрібно
    });
  });

  // Закриття модального вікна
  modalClose.forEach(btn => {
    btn.addEventListener('click', () => {
        modal.classList.remove('activeModal'); // Видаляємо клас для закриття модалки
        body.classList.remove('noScroll'); // Видаляємо клас для прокручування
    });
  });

  modal.addEventListener('click', (e) => {
    // Перевіряємо, чи клікнули поза контейнером
    if (!modalContainer.contains(e.target)) { 
        modal.classList.remove('activeModal');
        body.classList.remove('noScroll');
    }
  });
}

const timeOptions = {
  "monday": [
    { name: "09:00", value: "09:00", disabled: true },
    { name: "09:30", value: "09:30", disabled: true },
    { name: "10:00", value: "10:00", disabled: true },
    { name: "10:30", value: "10:30", disabled: true },
    { name: "11:00", value: "11:00", disabled: true },
    { name: "11:30", value: "11:30", disabled: true },
    { name: "12:00", value: "12:00", disabled: true },
    { name: "12:30", value: "12:30", disabled: true },
    { name: "13:00", value: "13:00", disabled: false },
    { name: "13:30", value: "13:30", disabled: false },
    { name: "14:00", value: "14:00", disabled: true },
    { name: "14:30", value: "14:30", disabled: true },
    { name: "15:00", value: "15:00", disabled: true },
    { name: "15:30", value: "15:30", disabled: true },
    { name: "16:00", value: "16:00", disabled: true },
    { name: "16:30", value: "16:30", disabled: true },
    { name: "17:00", value: "17:00", disabled: false },
    { name: "17:30", value: "17:30", disabled: false },
    { name: "18:00", value: "18:00", disabled: false },
    { name: "18:30", value: "18:30", disabled: false }
  ],
  "tuesday": [
    { name: "09:00", value: "09:00", disabled: true },
    { name: "09:30", value: "09:30", disabled: true },
    { name: "10:00", value: "10:00", disabled: true },
    { name: "10:30", value: "10:30", disabled: true },
    { name: "11:00", value: "11:00", disabled: true },
    { name: "11:30", value: "11:30", disabled: true },
    { name: "12:00", value: "12:00", disabled: true },
    { name: "12:30", value: "12:30", disabled: true },
    { name: "13:00", value: "13:00", disabled: false },
    { name: "13:30", value: "13:30", disabled: false },
    { name: "14:00", value: "14:00", disabled: true },
    { name: "14:30", value: "14:30", disabled: true },
    { name: "15:00", value: "15:00", disabled: true },
    { name: "15:30", value: "15:30", disabled: true },
    { name: "16:00", value: "16:00", disabled: true },
    { name: "16:30", value: "16:30", disabled: true },
    { name: "17:00", value: "17:00", disabled: false },
    { name: "17:30", value: "17:30", disabled: false },
    { name: "18:00", value: "18:00", disabled: false },
    { name: "18:30", value: "18:30", disabled: false }
  ],
  "wednesday": [
    { name: "09:00", value: "09:00", disabled: true },
    { name: "09:30", value: "09:30", disabled: true },
    { name: "10:00", value: "10:00", disabled: true },
    { name: "10:30", value: "10:30", disabled: true },
    { name: "11:00", value: "11:00", disabled: true },
    { name: "11:30", value: "11:30", disabled: true },
    { name: "12:00", value: "12:00", disabled: true },
    { name: "12:30", value: "12:30", disabled: true },
    { name: "13:00", value: "13:00", disabled: false },
    { name: "13:30", value: "13:30", disabled: false },
    { name: "14:00", value: "14:00", disabled: true },
    { name: "14:30", value: "14:30", disabled: true },
    { name: "15:00", value: "15:00", disabled: true },
    { name: "15:30", value: "15:30", disabled: true },
    { name: "16:00", value: "16:00", disabled: true },
    { name: "16:30", value: "16:30", disabled: true },
    { name: "17:00", value: "17:00", disabled: false },
    { name: "17:30", value: "17:30", disabled: false },
    { name: "18:00", value: "18:00", disabled: false },
    { name: "18:30", value: "18:30", disabled: false }
  ],
  "thursday": [
    { name: "09:00", value: "09:00", disabled: true },
    { name: "09:30", value: "09:30", disabled: true },
    { name: "10:00", value: "10:00", disabled: true },
    { name: "10:30", value: "10:30", disabled: true },
    { name: "11:00", value: "11:00", disabled: true },
    { name: "11:30", value: "11:30", disabled: true },
    { name: "12:00", value: "12:00", disabled: true },
    { name: "12:30", value: "12:30", disabled: true },
    { name: "13:00", value: "13:00", disabled: false },
    { name: "13:30", value: "13:30", disabled: false },
    { name: "14:00", value: "14:00", disabled: true },
    { name: "14:30", value: "14:30", disabled: true },
    { name: "15:00", value: "15:00", disabled: true },
    { name: "15:30", value: "15:30", disabled: true },
    { name: "16:00", value: "16:00", disabled: true },
    { name: "16:30", value: "16:30", disabled: true },
    { name: "17:00", value: "17:00", disabled: false },
    { name: "17:30", value: "17:30", disabled: false },
    { name: "18:00", value: "18:00", disabled: false },
    { name: "18:30", value: "18:30", disabled: false }
  ],
  "friday": [
    { name: "09:00", value: "09:00", disabled: true },
    { name: "09:30", value: "09:30", disabled: true },
    { name: "10:00", value: "10:00", disabled: true },
    { name: "10:30", value: "10:30", disabled: true },
    { name: "11:00", value: "11:00", disabled: true },
    { name: "11:30", value: "11:30", disabled: true },
    { name: "12:00", value: "12:00", disabled: true },
    { name: "12:30", value: "12:30", disabled: true },
    { name: "13:00", value: "13:00", disabled: false },
    { name: "13:30", value: "13:30", disabled: false },
    { name: "14:00", value: "14:00", disabled: true },
    { name: "14:30", value: "14:30", disabled: true },
    { name: "15:00", value: "15:00", disabled: true },
    { name: "15:30", value: "15:30", disabled: true },
    { name: "16:00", value: "16:00", disabled: true },
    { name: "16:30", value: "16:30", disabled: true },
    { name: "17:00", value: "17:00", disabled: false },
    { name: "17:30", value: "17:30", disabled: false },
    { name: "18:00", value: "18:00", disabled: false },
    { name: "18:30", value: "18:30", disabled: false }
  ],
  "saturday": [
    { name: "09:00", value: "09:00", disabled: true },
    { name: "09:30", value: "09:30", disabled: true },
    { name: "10:00", value: "10:00", disabled: true },
    { name: "10:30", value: "10:30", disabled: true },
    { name: "11:00", value: "11:00", disabled: true },
    { name: "11:30", value: "11:30", disabled: true },
    { name: "12:00", value: "12:00", disabled: true },
    { name: "12:30", value: "12:30", disabled: true },
    { name: "13:00", value: "13:00", disabled: false },
    { name: "13:30", value: "13:30", disabled: false },
    { name: "14:00", value: "14:00", disabled: true },
    { name: "14:30", value: "14:30", disabled: true },
    { name: "15:00", value: "15:00", disabled: true },
    { name: "15:30", value: "15:30", disabled: true },
    { name: "16:00", value: "16:00", disabled: false },
    { name: "16:30", value: "16:30", disabled: false },
    { name: "17:00", value: "17:00", disabled: false },
    { name: "17:30", value: "17:30", disabled: false },
    { name: "18:00", value: "18:00", disabled: false },
    { name: "18:30", value: "18:30", disabled: true }
  ],
  "sunday": [
    { name: "09:00", value: "09:00", disabled: true },
    { name: "09:30", value: "09:30", disabled: true },
    { name: "10:00", value: "10:00", disabled: true },
    { name: "10:30", value: "10:30", disabled: true },
    { name: "11:00", value: "11:00", disabled: true },
    { name: "11:30", value: "11:30", disabled: true },
    { name: "12:00", value: "12:00", disabled: true },
    { name: "12:30", value: "12:30", disabled: true },
    { name: "13:00", value: "13:00", disabled: false },
    { name: "13:30", value: "13:30", disabled: false },
    { name: "14:00", value: "14:00", disabled: true },
    { name: "14:30", value: "14:30", disabled: true },
    { name: "15:00", value: "15:00", disabled: true },
    { name: "15:30", value: "15:30", disabled: true },
    { name: "16:00", value: "16:00", disabled: false },
    { name: "16:30", value: "16:30", disabled: false },
    { name: "17:00", value: "17:00", disabled: false },
    { name: "17:30", value: "17:30", disabled: false },
    { name: "18:00", value: "18:00", disabled: false },
    { name: "18:30", value: "18:30", disabled: true }
  ]
};

// Налаштування для datepicker
const options = {
    customDays: [ 'НД', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ' ],
    customMonths: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
    position: 'br',
    minDate: new Date(),
    startDay: 1,
    alwaysShow: true,
    formatter: (input, date, instance) => {
      formaterDate(input , date)
        // Оновлення списку радіокнопок після вибору дати
    },
    onSelect: (instance, date) => {
      updateTimeRadios(date);
    }
};

function formaterDate(input, date, time){
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedDate = dd + '.' + mm + '.' + yyyy + ' ' + (time ? time : '') // time
  input.value = formattedDate;
}

// Ініціалізація datepicker
const pickerTrigger = document.querySelector('.online-modal__datepicker');
let datePicker
if (pickerTrigger) {
  datePicker = datepicker(pickerTrigger, options);
}

// Функція для оновлення радіокнопок на основі вибраного дня
function updateTimeRadios(selectedDate) {
  const timeRadiosContainer = document.getElementById('timeRadios');
  console.log(selectedDate)
  if(!selectedDate){
    timeRadiosContainer.innerHTML = '';
  }else{
    const dayOfWeek = selectedDate.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
    const timesForDay = timeOptions[dayOfWeek] || [];

    // Очистка контейнера перед додаванням нових радіокнопок
    timeRadiosContainer.innerHTML = '';

    // Генерація радіокнопок на основі обраного дня
    timesForDay.forEach((time, index) => {
        const radioId = `time${index + 1}`;

        const radioDiv = document.createElement('div');
        radioDiv.className = 'online-modal__radio';

        const input = document.createElement('input');
        input.type = 'radio';
        input.name = 'online-modal__radio';
        input.id = radioId;
        input.value = time.value;
        input.disabled = time.disabled;

        const label = document.createElement('label');
        label.setAttribute('for', radioId);
        label.innerText = time.name;


        input.addEventListener('change', () => {
          formaterDate(pickerTrigger , datePicker?.dateSelected, input.value);
        });

        radioDiv.appendChild(input);
        radioDiv.appendChild(label);

        timeRadiosContainer.appendChild(radioDiv);
    });
  }

}


