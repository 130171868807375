import IMask from 'imask'; // import phone mask
import { Loader } from '@googlemaps/js-api-loader';


export function isSpoiler() {

    // Variables
        const tabs = document.querySelectorAll('.item-spoiler');
    // Functions
        const toggleTab = (event) => {
            event.currentTarget.classList.toggle('active');
        };
    // Event Listeners
        tabs.forEach((tab) => {
            tab.addEventListener('click', toggleTab);
        });
    }

isSpoiler()

export function isSpoilerPrice() {

    // Variables
        const tabs = document.querySelectorAll('.prices__block-header');
    // Functions
        const toggleTab = (event) => {

            event.currentTarget.classList.toggle('active');
            event.currentTarget.parentElement.classList.toggle('active')
        };
    // Event Listeners
        tabs.forEach((tab) => {
            tab.addEventListener('click', toggleTab);
        });

    }

isSpoilerPrice()


// export function isTabsResponse() {
// todo: перевірити всі інші таби на сайті під цю логіку

const dataTabGroup = document.querySelectorAll('[data-tab-group]');

if (dataTabGroup) {
    dataTabGroup.forEach((tabGroup) => {
        const dataTab = document.querySelectorAll(`[data-tab-group="${tabGroup.dataset.tabGroup}"] [data-tab]`);
        const dataContent = document.querySelectorAll(`[data-tab-group="${tabGroup.dataset.tabGroup}"] [data-content]`);

        const resetAllTab = () => {
            dataContent.forEach((content) => {
                content?.classList.add('d-none');
            })

            dataTab.forEach((tab) => {
                tab?.classList.remove('active');
                // console.log('remove-active')
            })
        }

        dataTab.forEach((tab) => {
            resetAllTab()
            tab.addEventListener('click', (event) => {
                // console.log('click')
                const thisTab = document.querySelector(`[data-tab-group="${tabGroup.dataset.tabGroup}"] [data-tab="${event.target.dataset.tab}"]`);
                const thisTabContent =  document.querySelector(`[data-tab-group="${tabGroup.dataset.tabGroup}"] [data-content="${event.target.dataset.tab}"]`);

                resetAllTab()
                thisTabContent?.classList.remove('d-none');
                // console.log(thisTabContent)
                thisTab?.classList.add('active')
            })
        })

        dataTab[0].click()
    })
}

function OTPInput() {
	const inputs = document.querySelectorAll('#otp > *[id]');
	for (let i = 0; i < inputs.length; i++) {
		inputs[i].addEventListener('keydown', function(event) {
			if (event.key === "Backspace") {
			inputs[i].value = '';
			if (i !== 0)
			inputs[i - 1].focus();
		}
		else {
			if (i === inputs.length - 1 && inputs[i].value !== '') {
				return true;
			} else if (event.keyCode > 47 && event.keyCode < 58) {
				inputs[i].value = event.key;
				if (i !== inputs.length - 1)
					inputs[i + 1].focus();
				event.preventDefault();
			} else if (event.keyCode > 64 && event.keyCode < 91) {
				inputs[i].value = String.fromCharCode(event.keyCode);
				if (i !== inputs.length - 1)
				inputs[i + 1].focus();
				event.preventDefault();
		  }
		}
	  });
	}
}
OTPInput();

function filtersDoctorsInit(){
    let filtersInit = document.querySelector('.doctors-main__filters-init')
    let filtersContainer = document.querySelector('.doctors-main__filters')
    let closing = document.querySelector('.doctors-main__filters-close')
    let header = document.querySelector('.header')
    let body = document.querySelector('body')
    if(filtersInit) {
        filtersInit.addEventListener('click', () => {
            // console.log('OPEN')
            header.classList.add('headerFixed')
            body.classList.add('noScroll')
            console.log(header.classList.contains('scrolled-down') , 'asdasd')
            if(header.classList.contains('scrolled-down')){
                filtersContainer.classList.add ('filtersActive')

            }else{
                filtersContainer.classList.add ('filtersActive')
                filtersContainer.classList.add ('with-padding')
            }
        })

        closing.addEventListener('click', () => {
            filtersContainer.classList.remove('filtersActive')
            if(filtersContainer.classList.contains ('with-padding')){
                filtersContainer.classList.remove ('with-padding')
            }
            body.classList.remove('noScroll')
            header.classList.remove('headerFixed')
        })


        // body.addEventListener('click', (event) => {
        //     const self = event.target.closest('.doctors-main__filters-init');
        //     if (!self) {
        //         console.log('CLOSE')
        //         filtersContainer.classList.remove('filtersActive')
        //     }
        // })
    }
}
filtersDoctorsInit();

function filtersReviewsInit(){
    let filtersInit = document.querySelector('.reviews__filters-init')
    let filtersContainer = document.querySelector('.reviews__filters')
    let closing = document.querySelector('.reviews__filters-close')
    let header = document.querySelector('.header')
    let body =document.querySelector('body')
    if(filtersInit) {
        filtersInit.addEventListener('click', () => {
            // console.log('OPEN')
            header.classList.add('headerFixed')
            body.classList.add('noScroll')
            filtersContainer.classList.add ('filtersActive')
        })

        closing.addEventListener('click', () => {
            filtersContainer.classList.remove('filtersActive')
            body.classList.remove('noScroll')
            header.classList.remove('headerFixed')
        })


        // body.addEventListener('click', (event) => {
        //     const self = event.target.closest('.doctors-main__filters-init');
        //     if (!self) {
        //         console.log('CLOSE')
        //         filtersContainer.classList.remove('filtersActive')
        //     }
        // })
    }
}
filtersReviewsInit();


function filtersBlogInit(){
    let filtersInit = document.querySelector('.blog__filters-init')
    let filtersContainer = document.querySelector('.blog__filters')
    let closing = document.querySelector('.blog__filters-close')
    let header = document.querySelector('.header')
    let body =document.querySelector('body')
    if(filtersInit) {
        filtersInit.addEventListener('click', () => {
            // console.log('OPEN')
            header.classList.add('headerFixed')
            body.classList.add('noScroll')
            filtersContainer.classList.add ('filtersActive')
        })

        closing.addEventListener('click', () => {
            filtersContainer.classList.remove('filtersActive')
            body.classList.remove('noScroll')
            header.classList.remove('headerFixed')
        })


        // body.addEventListener('click', (event) => {
        //     const self = event.target.closest('.doctors-main__filters-init');
        //     if (!self) {
        //         console.log('CLOSE')
        //         filtersContainer.classList.remove('filtersActive')
        //     }
        // })
    }
}
filtersBlogInit();


function selectReviews(){
    let selectBtn = document.querySelectorAll('.selectBtn')

    let body =document.querySelector('body')
    let selectDropdown = document.querySelectorAll('.selectDropdown')
    const on = (listener, query, fn) => {
        document.querySelectorAll(query).forEach(item => {
            item.addEventListener(listener, el => {

                fn(el);
                if (el.srcElement.hasAttribute('data-group')) {
                    console.log(el.target.parentElement.children)
                    const thisOptionBlock = el.target.parentElement.children
                    for(const[key, value] of Object.entries(thisOptionBlock)) {
                        if (value.tagName === 'INPUT') {

                            // const input = document.querySelector(`input[name="${el.srcElement.dataset.group}"]`)
                            const input = value
                            const dataValue = el.srcElement.dataset.value
                            input.value = dataValue

                            const errorBlok = document.querySelector(`[data-error="${input.name}"]`)
                            if (errorBlok) {
                                errorBlok.innerText = ""
                            }

                            if (input.hasAttribute('data-select') && input.dataset.select === 'mask' ) {
                                maskForPhone()
                            }
                            // if (input.hasAttribute('data-select') && input.dataset.select === 'mask2') {
                            //     maskForPhone2()
                            // }
                            if (input.hasAttribute('data-select') && input.dataset.select === 'mask3') {
                                maskForPhone3()
                            }

                        }
                    }


                }
            })
        })
    }

    on('click', '.selectBtn', item => {

        selectDropdown.forEach(dropdown => {
            if (dropdown !== item.target.nextElementSibling) {
              dropdown.classList.remove('toggle');
            }
          });

          item.target.nextElementSibling.classList.toggle('toggle');
    });
    on('click', '.option', item => {
        item.target.parentElement.classList.remove('toggle');

        const parent = item.target.closest('.select').children[0];
        parent.setAttribute('data-type', item.target.getAttribute('data-type'));
        parent.innerText = item.target.innerText;
    })

    body.addEventListener('click', (event) => {
        const self = event.target.closest('.select');
        if (!self) {
            selectDropdown.forEach(i => {
                if(i.classList.contains('toggle')) {
                    i.classList.remove('toggle')
                }
            })
        }

    })
}
selectReviews()

function maskForPhone() {
    const parentElements = document.querySelectorAll('.modal__select-mask');

    parentElements.forEach((parent) => {
        const input = parent.querySelector('[data-select="mask"]');
        const resInp = parent.querySelector('.reset-input');

        if (resInp && input) {
            const countryMasks = {
                "UKR": { placeholder: '+380 00 000 00 00', mask: '+{38} 000 000 0000' },
                "PL": { placeholder: '+48 000 000 00 00', mask: '+{48} 000 000 0000' },
                "RO": { placeholder: '+40 000 000 00 00', mask: '+{40} 000 000 0000' },
                "MD": { placeholder: '+373 000 000 000', mask: '+{373} 000 000 000' },
                "BG": { placeholder: '+359 000 000 000', mask: '+{359} 000 000 000' },
                "CZ": { placeholder: '+420 000 000 000', mask: '+{420} 000 000 000' }
            };

            const selectedCountry = input.value;
            const { placeholder, mask } = countryMasks[selectedCountry] || countryMasks["UKR"];

            // Створюємо поле з відповідним плейсхолдером у межах поточного батьківського елемента
            resInp.innerHTML = `
                <input class="modal__input phone-mask" type="text" name="phone" placeholder="${placeholder}">
            `;

            const element = parent.querySelector('input.phone-mask');
            const maskOptions = { mask };
            IMask(element, maskOptions);
        }
    });
}

// Викликаємо функцію
maskForPhone();




function maskForPhone3() {
    const input = document.querySelector('[data-select="mask3"]');

    const resInp = document.querySelector('.reset-input3')
    if(resInp){

        let placeholder = '+380 00 000 00 00'

        switch (input.value) {
            case "UKR":
                placeholder = '+380 00 000 00 00'
            break;
            case 'PL':
                placeholder = '+48 000 000 0000'
            break;
            case "RO":
                placeholder = '+40 000 000 0000'
            break;
            case 'MD':
                placeholder = '+373 000 000 000'
            break;
            case "BG":
                placeholder = '+359 000 000 000'
            break;
            case 'CZ':
                placeholder = '+420 000 000 000'
            break;
        }

        resInp.innerHTML = `<input class="modal__input phone-mask3" type="text" name="phone" placeholder="${placeholder}">`

        const element = document.querySelector(`input.phone-mask3`);

        // element.value = ''
        var maskOptions = {
            mask: '+{38}(000)000-00-00',
            // lazy: false,
            // placeholderChar: '0'
        }

        let mask = IMask(element, maskOptions)

        switch (input.value) {
            case "UKR":
                mask.updateOptions({mask: '+{38} 000 000 0000'});
            break;
            case 'PL':
                mask.updateOptions({mask: '+{48} 000 000 0000'});
            break;
            case "RO":
                mask.updateOptions({mask: '+{40} 000 000 0000'});
            break;
            case 'MD':
                mask.updateOptions({mask: '+{373} 000 000 000'});
            break;
            case "BG":
                mask.updateOptions({mask: '+{359} 000 000 000'});
            break;
            case 'CZ':
                mask.updateOptions({mask: '+{420} 000 000 000'});
            break;

        }
    }

}
maskForPhone3()

function submitReviewForm(){
    let btn = document.getElementById('review-submit-btn');
    // console.log(btn);
	if (btn) {
		btn.addEventListener('click', () => {

			document.getElementById('review-form').submit()
		})
	}
}

submitReviewForm()


function stickyAnchors() {

    var activeAnchor = null; // Змінна для зберігання посилання на активний якір

    window.addEventListener('scroll', function () {
      var sidebarAnchors = document.getElementsByClassName('service__list-anchor');

      for (var i = 0; i < sidebarAnchors.length; i++) {
        var anchor = sidebarAnchors[i];
        var target = document.querySelector(anchor.getAttribute('href'));

        // Перевірка, чи блок знаходиться на екрані
        if (isElementInViewport(target)) {
          if (activeAnchor) {
            activeAnchor.classList.remove('active');
          }
          activeAnchor = anchor;
          anchor.classList.add('active');
          break; // Зупиняємо цикл після знаходження активного якоря
        }
      }
    });

    // Функція, що перевіряє, чи елемент знаходиться на екрані
    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

}

stickyAnchors()

function stepsOnScroll() {
    let container = document.querySelector('.steps__content')
    const steps = document.querySelector('.steps__svgs')
    function addGradient() {
        const targetPosition = container.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        let percent = 0
        const elementHeight = container.offsetHeight;
        const elementTop = container.offsetTop;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (targetPosition < windowHeight) {
            const distance = scrollTop - elementTop;
            percent = Math.round(distance / elementHeight * 100) + 55;
            steps.style.background = `linear-gradient(to bottom, #AB332F 0%, #AB332F ${percent}%, #E3E6EE ${percent}%, #E3E6EE 100%`
        }
    }

    if (container && steps) {
        window.addEventListener('DOMContentLoaded', addGradient)
        window.addEventListener('scroll', addGradient)

    }

}
stepsOnScroll()



function modalInit(){
	let buttons = document.querySelectorAll('.modalbtn')
	let modal = document.querySelector('.modal')
	let wrapper = document.querySelector('body')
    let closes = document.querySelectorAll('.close-modal-form')
	// let links = document.querySelectorAll('.link')

    if(modal){
        closes.forEach(close => {
            close.addEventListener('click', () => {
                modal.classList.remove('activeModal')
                wrapper.classList.remove('noScroll')
            })
        })
    }
    buttons.forEach(button => {
		button.addEventListener('click', () => {
			modal.classList.toggle('activeModal')
			wrapper.classList.toggle('noScroll')
		})
	})
    if (modal) {
        modal.addEventListener('click', (event) => {
            const self = event.target.closest('.modal__form');
            if (!self) {
                modal.classList.remove('activeModal')
                wrapper.classList.remove('noScroll')
            }
        })
    }
}
modalInit()


export function alert() {
	let init = document.querySelector('.alert')
	let closing = document.querySelector('.alert__close')
    let body =document.querySelector('body')

    if(init) {
        closing.addEventListener('click', () => {
            init.classList.remove('alert_active')
        })


        body.addEventListener('click', (event) => {
            const self = event.target.closest('.alert');
            if (!self) {
                init.classList.remove('alert_active')
            }
        })
    }
}
alert();

export function thanks() {
	let init = document.querySelector('.thanks')
	let closing = document.querySelector('.thanks__close')
    let body =document.querySelector('body')

    if(init) {
        closing.addEventListener('click', () => {
            init.classList.remove('thanks_active')
        })


        body.addEventListener('click', (event) => {
            const self = event.target.closest('.thanks__block');
            if (!self) {
                init.classList.remove('thanks_active')
            }
        })
    }
}
thanks();


export function socInit() {
	let init = document.querySelector('.soc__init')
	let list = document.querySelector('.soc__block')
    let body =document.querySelector('body')
    let modal = document.querySelector('.popup-contact')
    let close  = document.querySelector('.popup-contact__close')
    let initFromHeader = document.querySelector('.header__top-left-mobile-btn')

    if(init){
        init.addEventListener('click', () => {
            // list.classList.toggle('soc_activ')
            modal.classList.add('active-popup')
            body.classList.add('noScroll')
        })
    }
    
    if(initFromHeader){
        initFromHeader.addEventListener('click', () => {
            // list.classList.toggle('soc_activ')
            modal.classList.add('active-popup')
            body.classList.add('noScroll')
        })
    }
    

    close.addEventListener('click', () => {
        modal.classList.remove('active-popup')
        body.classList.remove('noScroll')
    })

    modal.addEventListener('click', (event) => {
        const self = event.target.closest('.popup-contact__content');
        if (!self) {
            modal.classList.remove('active-popup')
            body.classList.remove('noScroll')
        }
    })
}
socInit();

// mask for date

const element = document.querySelector(`input.modal__datepicker`);


const d = new Date();
let year = d.getFullYear()

var lazyMask = IMask(
    document.querySelector(`input.modal__datepicker`),
    {
      mask: Date,
      autofix: true,
      blocks: {
        d: {mask: IMask.MaskedRange, placeholderChar: 'd', from: 1, to: 31, maxLength: 2},
        m: {mask: IMask.MaskedRange, placeholderChar: 'm', from: 1, to: 12, maxLength: 2},
        Y: {mask: IMask.MaskedRange, placeholderChar: 'y', from: year, to: year+1000, maxLength: 4}
      }
    }
);


export function actionsInit() {
	let init = document.querySelector('.actions__content-desctop-activate')
	let list = document.querySelector('.actions__content-desktop')
    let swiper =document.querySelector('.actions__content-mobile')

        if(init && list &&  swiper){
            init.addEventListener('click', () => {
                list.classList.add('active-actions')
                swiper.classList.add('delete-swiper-actions')
                init.classList.add('delete-button')
            })
        }
}
actionsInit();

function modalInitImg() {
    let inits = document.querySelectorAll('.inits-img');
    let modal = document.querySelector('.modal-img');
    let wrapper = document.querySelector('body');
    let close = document.querySelector('.modal-img__close');
    let modalImage = document.querySelector('.modal-img__img img');

    inits.forEach(init => {
        init.addEventListener('click', () => {
            let photoUrl = init.dataset.init;
            modalImage.src = photoUrl;
            modal.classList.add('active-img');
            wrapper.classList.add('noScroll');
        });
    });

    close.addEventListener('click', () => {
        modal.classList.remove('active-img');
        wrapper.classList.remove('noScroll');
    });

    if (modal) {
        modal.addEventListener('click', (event) => {
            const self = event.target.closest('.modal-img__content');
            if (!self) {
                modal.classList.remove('active-img');
                wrapper.classList.remove('noScroll');
            }
        });
    }
}

modalInitImg();


function checkFirstElement(){
    const newsPage = document.querySelector('.section-one-news')
    const divs = document.querySelectorAll('.content-bloc div');
    if (divs.length > 0 && divs[0]) {
        divs[0].classList.add('first-div');

        const innerDivs = divs[0].querySelectorAll('div');
        if (innerDivs.length > 0 && innerDivs[0]) {
            innerDivs[0].classList.add('first-first-div');
        }
    }
    
}


checkFirstElement()


const tabButtons = document.querySelectorAll('.contact__label');
    if (tabButtons){
        tabButtons.forEach(tab => {
            tab.addEventListener('click', () => {
                const tabContentId = tab.getAttribute('data-tab-2');
                const tabContents = document.querySelectorAll('.contact-bottom-content');

                tabContents.forEach(content => {
                    content.classList.remove('active');
                });

                tabButtons.forEach(t => t.classList.remove('active'));
                tab.classList.add('active');

                const activeContent = document.getElementById(tabContentId);
                    if (activeContent) {
                    activeContent.classList.add('active');
                }
            });
            });
    }



// window.initMap = function() {

//     // function initMaps() {
//         const center = { lat: 50.49030399580581,lng:30.559069238082998}
//         const myLatLng1 = { lat: 50.458045902376234, lng: 30.47836774232872 };
//         const myLatLng2 = { lat: 50.50597049402832, lng: 30.618112642328725 };
//         const map = new google.maps.Map(googlemap , {
//             zoom: 11,
//             center: center,
//         });
//         const image = {
//             url:'/frontend/web/img/mapMark.svg',
//         };

//         const marker1 = new google.maps.Marker({
//             position: myLatLng1,
//             map,
//             icon:image,
//             link:'https://www.google.com/maps/place/%D0%A6%D0%B5%D0%BD%D1%82%D1%80+%D0%9E%D0%BA%D0%B0+-+%D0%BE%D1%84%D1%82%D0%B0%D0%BB%D1%8C%D0%BC%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0+%D0%BA%D0%BB%D1%96%D0%BD%D1%96%D0%BA%D0%B0/@50.4579332,30.4783892,17z/data=!3m1!4b1!4m6!3m5!1s0x40d4cf6a03b741c5:0xa9931b041fd9779d!8m2!3d50.4579332!4d30.4783892!16s%2Fg%2F11ckqrjqm3?entry=ttu',
//         });

//         const marker2 = new google.maps.Marker({
//             position: myLatLng2,
//             map,
//             icon: image,
//             link:'https://www.google.com/maps/place/%D0%A6%D0%B5%D0%BD%D1%82%D1%80+%D0%9E%D0%BA%D0%B0+-+%D0%BE%D1%84%D1%82%D0%B0%D0%BB%D1%8C%D0%BC%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0+%D0%BA%D0%BB%D1%96%D0%BD%D1%96%D0%BA%D0%B0/@50.4816134,30.482116,12z/data=!4m6!3m5!1s0x40d4d1f1283e8991:0xea6977d4996c7783!8m2!3d50.5058852!4d30.6181341!16s%2Fg%2F11lckyzpxf?hl=uk&entry=ttu'
//         });

//         marker1.addListener('click', function () {
//             openMapWindow(marker1);
//         });
//         marker2.addListener('click', function () {
//             openMapWindow(marker2);
//         });
//     // }
//     function openMapWindow(location) {
//         const mapUrl = location.link;
//         window.open(mapUrl, '_blank');
//     }


//     // window.initMap = initMap;
//     // if(googlemap){
//     //     initMaps()
//     // }
// }

document.addEventListener('DOMContentLoaded', function () {
    // Перевірка наявності куків при завантаженні сторінки
    if (document.cookie.indexOf('acceptedCookies=true') !== -1) {
        // Якщо куки існують, то додаємо клас 'd-none' для приховання елементу
        document.querySelector('.cookies').classList.add('d-none');
    }else{
        document.querySelector('.cookies').classList.add('visible');
    }
});

const cookies =  document.querySelector('.cookies')
const acceptCookie = document.querySelector('.cookies-accept')
const closeCookie = document.querySelector('.cookies-close')

function acceptCookies() {
    var currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + 1);
    var expires = currentDate.toUTCString();
    document.cookie = "acceptedCookies=true; expires=" + expires + "; path=/";
    cookies.classList.add('d-none');
}
if(cookies){
    acceptCookie.addEventListener('click', acceptCookies);
}
closeCookie.addEventListener('click', () => {
    cookies.classList.add('d-none')
})

var notificationItems = document.querySelectorAll('.header-warning-item');


// Дочекайтеся завантаження сторінки
document.addEventListener("DOMContentLoaded", function() {
    // console.log('dom loaded')
    var currentIndex = 0;

/*************  ✨ Codeium Command ⭐  *************/
    /**
     * Функція для зміни активного елемента в списку повідомлень.
     * Видаляє клас 'active-notify' у поточного елемента,
     * змінює індекс на наступний, і додає клас 'active-notify' до наступного елемента.
     *
/******  ca60802d-008c-47d1-bdb8-0be4fe52f2a2  *******/
    function changeActiveNotify() {

        notificationItems[currentIndex].classList.remove('active-notify');
        currentIndex = (currentIndex + 1) % notificationItems.length;
        notificationItems[currentIndex].classList.add('active-notify');
        // console.log('change active index', currentIndex)

    }
    if(notificationItems && notificationItems.length > 1){
        setInterval(changeActiveNotify, 3000);
    }

});








(async () => {
    // Ініціалізація Loader з API ключем та бібліотеками
    await new Loader({
        apiKey: 'AIzaSyAUAFqMrxYbodPtWIYswQcMCpRnYjXFUu4', // Вставте ваш API ключ сюди
        libraries: ['places'],
    }).importLibrary('places');

    let map;

    // Функція для ініціалізації карти
    const initMap = () => {
        map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: 50.49030399580581, lng: 30.559069238082998 }, // Координати для центру
            zoom: 11, // Початковий зум карти
            disableDefaultUI: true, // Вимкнення стандартного інтерфейсу карти
        });

        const image = {
            url: '/frontend/web/img/mapMark.svg', // Шлях до вашої кастомної іконки
        };

        // Додавання маркерів на карту
        const marker1 = new google.maps.Marker({
            position: { lat: 50.458045902376234, lng: 30.47836774232872 }, // Координати першого маркера
            map: map,
            icon: image, // Іконка маркера
            link: 'https://www.google.com/maps/place/%D0%A6%D0%B5%D0%BD%D1%82%D1%80+%D0%9E%D0%BA%D0%B0+-+%D0%BE%D1%84%D1%82%D0%B0%D0%BB%D1%8C%D0%BC%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0+%D0%BA%D0%BB%D1%96%D0%BD%D1%96%D0%BA%D0%B0/@50.4579332,30.4783892,17z/data=!3m1!4b1!4m6!3m5!1s0x40d4cf6a03b741c5:0xa9931b041fd9779d!8m2!3d50.4579332!4d30.4783892!16s%2Fg%2F11ckqrjqm3?entry=ttu',
        });

        const marker2 = new google.maps.Marker({
            position: { lat: 50.50597049402832, lng: 30.618112642328725 }, // Координати другого маркера
            map: map,
            icon: image,
            link: 'https://www.google.com/maps/place/%D0%A6%D0%B5%D0%BD%D1%82%D1%80+%D0%9E%D0%BA%D0%B0+-+%D0%BE%D1%84%D1%82%D0%B0%D0%BB%D1%8C%D0%BC%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0+%D0%BA%D0%BB%D1%96%D0%BD%D1%96%D0%BA%D0%B0/@50.4816134,30.482116,12z/data=!4m6!3m5!1s0x40d4d1f1283e8991:0xea6977d4996c7783!8m2!3d50.5058852!4d30.6181341!16s%2Fg%2F11lckyzpxf?hl=uk&entry=ttu',
        });

        // Додавання слухачів на маркери для відкриття карт
        marker1.addListener('click', () => openMapWindow(marker1));
        marker2.addListener('click', () => openMapWindow(marker2));

        function openMapWindow(marker) {
            const mapUrl = marker.link;
            window.open(mapUrl, '_blank');
        }
    };

    // Ініціалізація карти
    initMap();
})();


const setSpanUrls = () => {
    const urls = document.querySelectorAll('.href')
    if(urls) {
        urls.forEach(element => {
            element.addEventListener('click' ,() => {
                const href = element.getAttribute('data-href')
                window.location.href = href
            })
            element.addEventListener('hover' ,() => {
                element.css('cursor', 'pointer')
            })
        })
    }
}

setSpanUrls()


function toggleAddress (){
    const address = document.querySelector('.address')
    const initAddress = address.querySelector('.address-init')
    const addressList = address.querySelector('.address-list')
    const body = document.querySelector('body')
    
    initAddress.addEventListener('click' ,() => {
        address.classList.toggle('address-active')
    })
    addressList.addEventListener('click' ,() => {
        address.classList.remove('address-active')
    })
    
    body.addEventListener('click', (event) => {
        const self = event.target.closest('.address');
        if (!self) {
            if(address.classList.contains('address-active'))address.classList.remove('address-active')

        }
    });
}

toggleAddress()


